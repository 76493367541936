import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavBarStyled } from "./NavBar.style";
import { breakpoints } from '../../config/breakpoints';


function NavBar({ activeClassName, setActiveClassName }) {
    const navRef = useRef();

    useEffect(() => {
        let resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(entry => {
                const { width } = entry.contentRect;
                if (width >= breakpoints.l && activeClassName === "active") {
                    if (document.querySelector("body").style.overflow === "hidden") {
                        document.querySelector("body").style.overflow = "visible"
                    }
                    return setActiveClassName("")
                }
            });
        });
    
       resizeObserver.observe(navRef.current);

        return () => {
            resizeObserver.disconnect();
            resizeObserver = null;
        };
      }, [activeClassName, setActiveClassName]);
    

    return  (
        <NavBarStyled>
            <div className="nav-mobile">
                <a href="/" className="logo">
                    <img src="logo.svg" alt="logo"/>
                </a>
                <div
                    className="burger"
                    onClick={() => {
                        document.querySelector("body").style.overflow = "hidden"
                        return setActiveClassName("active")
                    }}
                >
                    <FontAwesomeIcon icon={faBars} size="2x" />
                </div>
            </div>

            
            <div className={`nav ${activeClassName}`} ref={navRef}>
                {
                    activeClassName === 'active' && (
                        <div 
                            className="close"
                            onClick={() => {
                                setActiveClassName("")
                                document.querySelector("body").style.overflow = "visible"
                            }}
                        >
                            <FontAwesomeIcon icon="fa-solid fa-xmark" size="2x" />                           
                        </div>
                    )
                }
                <div>
                    <a className="logo-desktop" href="/"><img src="logo.svg" alt="logo" /></a>
                </div>
                <div className={`menu-elements ${activeClassName}`}>
                    <div>
                        <span className="green">01.</span><a href="#about">À propos de moi</a>
                    </div>
                    <div>
                        <span className="green">02.</span><a href="#project">Projets</a>
                    </div>
                    <div>
                        <span className="green">03.</span><a href="#contact">Contact</a>
                    </div>
                    <div>
                        <a href="CV - Christian XU ETNA.pdf" target="blank" className="cv-button">C.V</a>
                    </div>
                </div>
            </div>   
        </NavBarStyled>
    )
}

export default NavBar;