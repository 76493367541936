import { FooterStyled } from "./Footer.style"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Footer() {

    return (
        <FooterStyled>
            <div className="footer">
                <div>
                    <a href="https://github.com/Christianxu99" target="blank"><FontAwesomeIcon icon="fa-brands fa-github" /></a>
                    <a href="https://www.linkedin.com/in/christianxu/" target="blank"><FontAwesomeIcon icon="fa-brands fa-linkedin-in" /></a>
                    <a href="mailto:christian.xu99@gmail.com" target="blank"><FontAwesomeIcon icon="fa-solid fa-envelope" /></a>
                </div>
                <div>
                    Designed &amp; Built by Christian XU
                </div>
                <div>
                    Copyright © 2025
                </div>
            </div>

        </FooterStyled>
    )
}

export default Footer;